import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styles from '@styles/components/nav.module.css';


const Nav = ({ siteTitle, logo }) => {
    const pages = [
        { title: "Home.", url: "/" },
        { title: "Merch.", url: "" },
        // { title: "Bio.", url: "/bio" },
        { title: "Video.", url: "/video" },
    ]
    const [menuOpened, toggleMenu] = useState(false);

    const handleClick = () => {
        toggleMenu(!menuOpened);
    }
    const handleKeyDown = (event) => {
        if (event.key !== 'Enter') return;
        toggleMenu(!menuOpened);
    }
    const links = pages.map((page, ind) => {
        if (page.title === 'Merch.') {
            return <li className={styles.navItem} key={page.title}><a className={styles.navLink} href="https://merch.eisband.com">{page.title}</a></li>
        }
        if (ind === pages.length - 1) {
            return <li className={styles.navItem} key={page.title}><Link className={styles.navLink} to={page.url}>{page.title}</Link></li>
        }
        return (<React.Fragment key={page.title}><li className={styles.navItem} ><Link className={styles.navLink} to={page.url}>{page.title}</Link></li></React.Fragment>);
    })
    const openedClass = menuOpened ? styles.opened : "";

    return (
        <>
            <header className={styles.header}>

                <nav className={styles.nav}>
                    <div className={styles.hamburger} onClick={handleClick} onKeyDown={handleKeyDown} tabIndex="0" role="button" aria-label="Toggle Mobile Menu">
                        {!menuOpened ?
                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" >
                                <line strokeWidth="3px" x1="0" y1="10" x2="50" y2="10" stroke="lightgrey" />
                                <line strokeWidth="3px" x1="5" y1="20" x2="45" y2="20" stroke="lightgrey" fill="white" />
                                <line strokeWidth="3px" x1="0" y1="30" x2="50" y2="30" stroke="lightgrey" />
                               
                            </svg>
                            :
                            <svg>
                                <line strokeWidth="3px" x1="0" y1="0" x2="50" y2="50" stroke="lightgrey" />
                                <line strokeWidth="3px" x1="0" y1="50" x2="50" y2="0" stroke="lightgrey" />
                            </svg>
                        }

                    </div>
                    <ul className={styles.navLinks}>
                        {links}
                    </ul>
                </nav>

            </header>



            <aside className={`${styles.mobileMenu} ${openedClass}`}>

                <ul className={styles.navLinksHamburger}>
                    {links}
                </ul>
            </aside>


        </>
    )
}

Nav.propTypes = {
    siteTitle: PropTypes.string,
}

Nav.defaultProps = {
    siteTitle: ``,
}

export default Nav


// {/* <div className={styles.logoContainer}>
//           <Img fluid={logo.childImageSharp.fluid} loading="eager" alt="Vinny Stodder Logo" />
//         </div> */}